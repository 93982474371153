import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const ProEmployee = () => {
  const headingbanner = {
    title: `Pro Employee`,
    content: `An Employee Assessment Platform`,
  };

  const data = {
    images: ["pro-employee.webp", "pro-employee-2.webp"],
    firstAlt: "UI UX of Employee Assessment platform for various job positions",
    firstTitle: "Assessment Platform",
    secondAlt: "Job Description of particular job position",
    secondTitle: "Job Description",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["SaaS"],
      },
      {
        title: "What we did",
        text: ["User Research", "UI UX Designing", "UI Development"],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `PorEmployee is an employee assessment platform that wanted to simplify the assessment process for all age groups.`,
          `Our primary challenge was to make task management as user-friendly as possible and so we worked extensively on the bucket sorting module.`,
          `We were tasked to-`,
        ],
        list: [
            `Simplify task management`,
            `Modernise assessment structure`,
            `Rethink the bucket sorting module`,
            `Explain scenarios before the assessment`
        ]
      },
      {
        title: "Solution",
        content: [
          `Our main goal was to keep the UI intuitive and guide the user at every action. We ensured that all the information is adequately displayed in the viewport.`,
          `We prioritized simplicity and removed any unnecessary elements, allowing for a clean and uncluttered UI design that improves overall usability.`,
          `In order to enhance the user's understanding and navigation of the system we created guided flows and actions within the user interface.`,
          `We also Optimized the viewport to ensure a consistent user experience across devices and screen sizes.`
        ],
      },
    ],
    image: ["pro-employee-problem.webp"],
    firstAlt: 'All Mockup Screens',
    firstTitle: 'Project Screens',
  };

  const projectData = {
    title: "Employee Test Profile",
    para: [
      `This platform showcases the individual assessment profile of every employee with the tests that they have to attempt. We highlighted the status of the test along with different other stages of assessment for a better employee experience.`,
    ],
    content: {
      image: ["pro-employee-projectone.webp"],
      imageAlt: "Employee Assessment and its status",
      imageTitle: 'Assessment Screen',
    },
  };

  const projectData2 = {
    title: `Job Description`,
    para: [
      `It is important to understand the job profile for any position. Hence, keeping this perspective in mind we dedicatedly designed a screen to provide a complete job description with the percentage of skill sets required for an improved assessment process.`,
    ],
    content: {
      image: ["pro-employee-projecttwo.webp"],
      imageAlt: "Employee's job description",
      imageTitle: 'Job Description',
    },
  };

  const projectData3 = {
    title: "Inbasket",
    para: [
      `We created this platform to give a detailed view of the inbasket assessment and also added a step bar for better employee understanding.`,
    ],
    content: {
      image: [
        "pro-employee-projectthree.webp",
        "pro-employee-projectthree-2.webp",
        "pro-employee-projectthree-3.webp",
        "pro-employee-projectthree-4.webp",
      ],
      firstAlt: 'UI Screen of Inbasket assessment',
      firstTitle: 'Inbasket',
      secondAlt: 'UI of test screen',
      secondTitle: 'Test Screen',
      thirdAlt: 'UI of Intray screens',
      thirdTitle: 'Intrays',
      fourthAlt: 'UI Screen to show test assessment status',
      fourthTitle: 'Assessment Status',
    },
  };

  const conclusionData = [
    {
      para: [
        `We simplified the assessment process for all age groups by focusing on intuitive UI design, guided flows, and optimizing the viewport and have successfully created a user-friendly system that enhances the user's understanding and navigation for ProEmployee. `,
        `With a clean and uncluttered design, users can easily manage tasks, modernize assessment structures, and utilize the bucket sorting module effectively. `,
        `Overall, our solution prioritizes simplicity and improves overall usability, ensuring a consistent user experience across different devices and screen sizes.`,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={30} />
      </div>
    </Layout>
  );
};

export default ProEmployee;

export const Head = () => (
  <>
    <meta name="keywords" content="pro employee, ui ux design, ui ux, case study, project" />
    <Seo title="ProEmployee - Octet Design Studio" description="We simplified the task management and optimized screen of an HRMS solution- Pro Employee with our service on UI UX Designing, User Research and UI Development." />
  </>
)